
/* Fuentes */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Estilos Generales */
body {
    font-family: "'Open Sans', sans-serif";
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    min-height: 100vh;
    position: relative;
    bottom: 4rem;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    color: #272660; /* Nuevo color para h1 */
    text-align: center;
    margin: 20px 0;
}

h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600; /* Cambiado a un peso más grueso */
    font-size: 1.8rem;
    color: #A06E51; /* Nuevo color para h2 */
    margin: 15px 0;
}

p{
    font-family: 'Montserrat', sans-serif;
}

ul {
    font-family: 'Open Sans', sans-serif;
    list-style-type: none;
    padding: 0;
}

div {
    font-family: 'Open Sans', sans-serif;
    margin: 10px 0;
}


/* Efectos de texto */
.titletext {
    padding: 15px;
    background-color: #000000; /* Color de fondo para el título */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra sutil */
}

.text {
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem; /* Agregar un poco de padding a los lados para que no esté tan pegado a los bordes */
}

/* Estilos de modo oscuro */
body.dark-mode {
    background-color: #121212; /* Fondo oscuro */
    color: #FFFFFF; /* Color de texto claro */
}

body.dark-mode h1 {
    color: #b0c4de; /* Color claro para h1 en modo oscuro */
}

body.dark-mode h2 {
    color: #e0e0e0; /* Color claro para h2 en modo oscuro */
}

body.dark-mode ul {
    color: #eaeaea; /* Color claro para listas */
}

body.dark-mode div {
    color: #eaeaea; /* Color claro para divs */
}

body.dark-mode .underheader {
    color: #eaeaea; /* Color claro para el encabezado inferior */
}

/* Estilos de navegación */
body.dark-mode nav {
    background-color: #222222; /* Fondo oscuro para nav */
}

body.dark-mode li {
    background-color: #333333; /* Fondo de lista */
    border: #eaeaea 2px solid; /* Borde claro */
    border-radius: 5px; /* Bordes redondeados */
}

body.dark-mode li:hover {
    background-color: #444444; /* Fondo al pasar el ratón */
}

body.dark-mode a {
    color: #eaeaea; /* Color de enlaces */
}

body.dark-mode a:hover {
    color: #121212; /* Color de enlace al pasar el ratón */
    background-color: #555555; /* Fondo al pasar el ratón */
}

/* Estilos del pie de página */
body.dark-mode footer {
    background-color: #222222; /* Fondo oscuro para el pie de página */
    color: #eaeaea; /* Color claro para el pie de página */
    padding: 20px;
    text-align: center;
}

/* Estilos de interruptores de modo */
body.dark-mode #light {
    display: none;
}

body.dark-mode #dark {
    display: block;
}
