/* Fuentes */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Raleway:wght@400;700&display=swap');

/* Estilos Generales */



nav { /* Color de fondo blanco */
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
   
    padding: 1rem 2rem; /* Espaciado interno */
    min-height: 10px;
}

/* When scrolled, hide the nav */
.hidden-nav {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
}

nav {
    transition: transform 0.3s ease-in-out;
}




.logo {
    height: 5rem; /* Ajustado para mantener proporciones */
    filter: hue-rotate(110deg);
    transition: filter 0.5s;
}


.text {
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

h1.header {
    color: white; /* Nuevo color para el título */
    font-size: 2.5rem; /* Ajustado tamaño */
    margin: 0; /* Eliminado margen */
    font-family: 'Montserrat', sans-serif; /* Nueva fuente */
}

a.header,
a.logo {
    display: contents; /* Mantiene el enlace sin interferencias de layout */
}

ul.line {
    cursor: pointer;
    display: flex;
    margin: 0; /* Eliminado margen */
    padding: 0; /* Eliminado padding */
}

a.navbutton {
    display: contents; /* Mantiene el enlace sin interferencias de layout */
}

li {
    padding: 10px 20px; /* Ajustado espaciado interno */
    margin-right: 20px; /* Ajustado margen */
    border-radius: 5px; /* Bordes más sutiles */
    transition: background-color 0.3s ease; /* Transición suave */
    color: #333333; /* Color de texto */
    font-family: 'Raleway', sans-serif; /* Nueva fuente */
    font-size: large;
}

li:hover {
    background-color: #F6E43A; /* Color de fondo al pasar el ratón */
    
}

li:active {
    color: white; /* Color del texto al hacer clic */
}



/* Hidden by default */
.menu {
    position: fixed;
    right: -250px; /* Offscreen */
    top: 0;
    width: 250px;
    height: 100%;
    background-color: #ffffff; /* Match nav background */
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    transition: right 0.3s ease; /* Slide animation */
    z-index: 1000; /* Above nav */
    margin: 0%;

}

/* Slide-in class */
.menu.open {
    right: 0;
}

/* Close button (X) styling */
.menu-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    cursor: pointer;
    color: #333333;
    display: none; /* Hidden by default */
}

.menu.open .menu-close {
    display: block; /* Show only when menu is open */
}

/* Make sure the menu list is visible */
.menu ul {
    padding: 0;
    margin: 2rem 0 0 0;
    list-style: none;
}

.menu li {
    padding: 10px 20px;
    border-bottom: 1px solid #E6E6E6;
    color: #333333;
    font-size: 1.2rem;
    cursor: pointer;
}

.menu-icon {
    display: none;
}


@media only screen and (max-width: 950px) and (orientation: portrait) {
    nav {
        height: 8%; /* Altura ajustada */
        padding: 0px;
    }

    div.text{
        padding: 0%;
    }

    h1.header {
        font-size: 2rem; /* Tamaño ajustado para pantallas pequeñas */
        margin-left: 1rem;
    }

    .logo {
        display: none; /* Ocultar logo en pantallas pequeñas */
    }

    li.normal-li {
        display: none; /* Ocultar elementos de la lista en pantallas pequeñas */
    }

    li.menu-li{
        text-align: center;
    }

        /* Hide the original nav links */
        .line {
            display: none;
        }
    
        /* Show the menu icon (hamburger) */
        .menu-icon {
            display: block;
            cursor: pointer;
            font-size: 2rem;
            margin-right: 1rem;
            margin-left: auto; /* This centers it to the right */
        }

}


