/* Container for settings page */
.settings {
  margin-top: 175px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 255, 255, 0.93);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  max-width: 70%;
  font-family: 'Arial', sans-serif;
  color: black;
  overflow: hidden;
  position: relative;
  /* For gradient line positioning */
}

.settings h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
}

.settings input[type="text"],
.settings select,
.settings textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 1.1rem;
}

.settings textarea {
  height: 100px;
  /* Add a specific height for the textarea */
  resize: vertical;
  /* Allow vertical resizing */
}

.settings button {
  background-color: #F04F54;
  border: 3px solid black;
  padding: 12px 25px;
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s;
  margin-top: 1rem;
  width: 100%;
}

.settings button:hover {
  background-color: #c73a3f;
  transform: translateY(-2px);
}

/* Styling checkboxes */
.settings input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
  /* Increase checkbox size */
}

.settings label {
  font-size: 1.1rem;
  font-weight: 500;
  margin-right: 20px;
}

.settings .checkbox-group {
  margin: 10px 0;
}

/* Responsive media query for smaller screens */
@media only screen and (max-width: 950px) {
  .settings {
    padding: 1.5rem;
    max-width: 90%;
  }

  .settings input[type="text"],
  .settings select,
  .settings textarea {
    font-size: 1rem;
  }

  .settings button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

/* Gradient line at the bottom of the settings container */
.settings .gradient-line {
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, #272660 0%, #5349EA 17.56%, #CDCAFF 32.82%, #F04F54 42.75%, #F6E43A 54.2%, #ECEAFF 64.89%, #95F5F1 75.57%, #33C2A4 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 0 8px 8px;
}