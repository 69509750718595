footer {
    background-color:#cecece; /* Darker background for contrast */
    width: 100%;
    padding: 20px;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    height: 70px;
    position: relative;
    top: 7rem;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    max-width: 1200px;
    margin: 0 auto; 
}

.copyright, .attribution {
    color: black; /* Text color for better readability */
    font-size: 1.2rem; 
    margin: 0 10px;
    text-align: center;
}

.attribution a {
    color: black; /* Light blue for links */
    text-decoration: none; /* Remove underline */
}

.attribution a:hover {
    text-decoration: underline; /* Underline on hover */
}

@media only screen and (max-width: 950px) and (orientation: portrait) {
    footer {
        margin-top: 3rem;
        height: auto; /* Adjust padding for smaller screens */
        max-width: 100%
    }

    .content {
        flex-direction: column; /* Stack items vertically */
        justify-content: center;
        align-items: center;
        text-align: center; /* Center text in mobile view */
    }

    .copyright, .attribution {
        font-size: 1rem;
        margin: 10px 0; /* Add some vertical space between elements */
    }
}