/* General page layout */
.tutor-profile-page {
    padding: 2rem;
    font-family: 'Arial', sans-serif;
    background-color: rgba(239, 239, 239, 1);
    margin-top: 175px;
    padding-left: 18%;
    padding-right: 18%;
}


/* Container for left and right sections */
.tutor-profile-container {
    display: flex;
    gap: 2rem;
    align-items: flex-start;
    min-height: 800px; /* Ensure enough height for the sticky sidebar */
}


/* Left section (main content) */
.tutor-profile-left {
    flex: 2;
}

/* Right section (sticky sidebar) */
.tutor-profile-right {
    flex: 1;
    overflow: visible; /* Ensure sticky element isn't clipped */
}

.tutor-profile-sticky {
    position: sticky;
    top: 20px; /* Distance from the top of the viewport */
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%; /* Ensure it spans the full width of the right section */
    box-sizing: border-box; /* Include padding in the width */
}


.tutor-profile-sticky p {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.tutor-profile-book-button {
    background-color: #f04f54;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.tutor-profile-book-button:hover {
    background-color: #c73a3f;
}

/* Profile header */
.tutor-profile-header {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

/* Profile picture */
.tutor-profile-pic img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 3px solid black;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Profile details */
.tutor-profile-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.tutor-profile-details p{
    text-align: left;
}

.tutor-profile-details h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin: 0;
}

.tutor-profile-details .tutor-profile-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    font-size: 1rem;
    color: #666;
}

.tutor-profile-details .tutor-profile-meta p {
    margin: 0;
}

.tutor-profile-details .tutor-profile-teaching h3 {
    font-size: 1.3rem;
    color: #f04f54;
    margin-bottom: 10px;
}

.tutor-profile-details .tutor-profile-teaching p {
    margin: 5px 0;
    font-size: 1rem;
    color: #555;
}

/* Main content sections */
.tutor-profile-description-section,
.tutor-profile-details-section,
.tutor-profile-availability-section,
.tutor-profile-reviews-section {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    line-height: 2;
}

.tutor-profile-description-section p,
.tutor-profile-details-section p,
.tutor-profile-availability-section p,
.tutor-profile-reviews-section p{
    line-height: 1.5;
    text-align: justify;
}

.tutor-profile-description-section h3,
.tutor-profile-details-section h3,
.tutor-profile-availability-section h3,
.tutor-profile-reviews-section h3 {
    margin-bottom: 1rem;
    color: #f04f54;
    font-size: 1.5rem;
}

.tutor-profile-reviews-section ul {
    list-style: none;
    padding: 0;
}

.tutor-profile-reviews-section li {
    padding: 10px;
    background-color: rgba(239, 239, 239, 0.6);
    margin-bottom: 10px;
    border-radius: 5px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .tutor-profile-container {
        flex-direction: column;
    }

    .tutor-profile-header {
        flex-direction: column;
        text-align: center;
    }

    .tutor-profile-right {
        position: static;
    }

    .tutor-profile-sticky {
        position: static;
        margin-top: 2rem;
    }

    .tutor-profile-picture img {
        width: 120px;
        height: 120px;
    }

    .tutor-profile-details h2 {
        font-size: 1.8rem;
    }
}
