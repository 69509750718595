.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
    /* Ensure it covers everything else */
}

.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    position: relative;
    animation: spin 2s linear infinite;
    /* Animation for spinning effect */
}

.loader::before {
    content: '';
    position: absolute;
    top: -16px;
    left: -16px;
    right: -16px;
    bottom: -16px;
    border-radius: 50%;
    border: 16px solid transparent;
    /* Make the rest of the border transparent */
    border-top: 16px solid transparent;
    /* Placeholder for the gradient */
    background: linear-gradient(to right, #272660 0%, #5349EA 20%, #CDCAFF 40%, #F04F54 60%, #F6E43A 80%, #33C2A4 100%);
    mask: radial-gradient(closest-side, transparent 96%, white 100%);
    /* Mask the center to create the top border effect */
    pointer-events: none;
    /* Make sure it doesn't interfere with interactions */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-screen p {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
}

.squarecalendar {
    background-color: white;
    width: 100%;
    height: 150px;
    display: flex;
    position: fixed;
    top: -10px;
}

.calendar {
    margin-top: 175px;
    margin-left: 10rem;
    margin-right: 10rem;
}


.wholecalendar {
    position: relative;
    top: 6rem;
    overflow: visible;
}


body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

.week-navigation {
    display: flex;
    border: 3px solid black;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: lightgrey;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
    /* Ensures the bar stays above other elements */
    position: fixed;
    top: 140px;
    width: 98%;
}

.week-navigation h3 {
    margin: 0;
    color: black;
}

.week-navigation button {
    background-color: #F04F54;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.week-navigation button:hover {
    background-color: #d5454a;
}


.calendar {
    display: flex;
    justify-content: center;
    padding: 20px;
    max-width: 100%;
    overflow-x: auto;
    overflow: visible;
}

.day-name {
    position: sticky;
    top: 210px;
    /* This makes it stick to the top of the viewport */
    z-index: 0;
    /* Optional: Ensures it stays above other content */
    background-color: white;
    height: 2rem;
}


.day-column {
    flex: 1;
    min-width: 20px;
    margin: 0 10px;
    border-radius: 4px;

}

.day-column h4 {
    text-align: center;
    background-color: #F04F54;
    color: white;
    margin: 0;
    padding: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.time-slot {
    text-align: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.time-slot.available {
    background-color: white;
    color: black;
}

.time-slot.available:hover {
    background-color: #95F5F1;
}

.time-slot.available:hover+.time-slot.available {
    background-color: #95F5F1;
}


.time-slot.blocked {
    background-color: #c6c6c6;
    color: #c6c6c6;
    cursor: not-allowed;
}

.time-slot.selected {
    background-color: #33C2A4;
    color: white;
}

.time-slot.selected:hover {
    background-color: #33C2A4;
    color: white;
}

.time-slot.unavailable {
    display: none;
}

.booking-confirmation {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.booking-confirmation p {
    margin: 0;
    color: #362010;
}

.booking-confirmation button {
    background-color: #F04F54;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 5px;
}

.booking-confirmation button:hover {
    background-color: #CDCAFF;
}


@media only screen and (max-width: 1100px) {
    .day-name {
        font-size: .7rem;
        height: 20px;
        position: sticky;
        top: 235px;
    }
}

@media only screen and (max-width: 990px)  {
    
    .week-navigation{
        height: 3.75rem;
    }
    
    .previous-next {
        font-size: .7rem;
        height: 3rem;
    }

    .week-range{
        font-size: .8rem;
    }

    .day-name {
        font-size: .5rem;
    }

    .week-navigation {
        width: 94%;
    }

    button {
        width: 100px;
    }

    .calendar {
        margin-left: 0;
        margin-right: 0;
        margin-top: 50;
    }
}