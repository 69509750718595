/* Login Component Specific Styles */
.login-container {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9; /* Fondo suave para un ambiente moderno */
    background: linear-gradient(to bottom, #f9f9f9 0%, #f0f0f0 10%);
}

.login-card {
    width: 100%;
    max-width: 400px; /* Tamaño máximo para adaptarse a pantallas grandes */
    color: #4B5563;
    padding: 2rem; /* Espaciado interno más generoso */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); /* Sombra más pronunciada */
    border-radius: 1rem; /* Bordes más redondeados */
    background-color: white;
    box-sizing: border-box; /* Asegurarse de que padding y border se incluyan en el ancho */
}

.login-title {
    text-align: center;
    margin-bottom: 1.5rem; /* Espacio entre el título y el formulario */
}

.login-title h3 {
    color: #1F2937;
    font-size: 1.5rem; /* Tamaño de fuente más grande */
    font-weight: 600;
}

.login-form {
    margin-top: 1.25rem;
}

.login-label {
    font-size: 0.9rem; /* Tamaño de fuente un poco más grande */
    font-weight: bold;
    color: #4B5563;
}

.login-input {
    width: 100%;
    margin-top: 0.5rem;
    padding: 0.75rem; /* Aumento del padding para un mejor tacto */
    color: #6B7280;
    background-color: #f3f4f6; /* Fondo más suave para los campos */
    border: 1px solid #D1D5DB;
    border-radius: 0.5rem;
    box-sizing: border-box; /* Asegura que el padding y el borde se incluyan en el ancho */
    transition: border-color 0.3s, background-color 0.3s;
}

.login-input:focus {
    border-color: #4F46E5; /* Color de borde en enfoque */
    background-color: white; /* Cambiar a blanco al enfocar */
}

.login-error {
    color: #DC2626;
    font-weight: bold;
    margin-top: 0.5rem; /* Espacio entre el mensaje de error y el campo */
}

.login-button {
    width: 100%;
    padding: 0.75rem; /* Aumento de padding para botones */
    font-weight: 600;
    color: white;
    border-radius: 0.5rem;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin-top: 1rem;
}

.login-button-active {
    background-color: #6366F1; /* Color activo del botón */
}

.login-button-hover {
    background-color: #4338CA; /* Color en hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); /* Sombra al pasar el ratón */
}

.login-button-disabled {
    background-color: #D1D5DB; /* Color de fondo del botón deshabilitado */
    cursor: not-allowed;
}

.login-footer {
    text-align: center;
    font-size: 0.875rem;
    margin-top: 1rem; /* Espacio superior para separar del formulario */
}

.login-divider {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem; /* Espacio superior para separar del footer */
}

.login-divider-line {
    flex-grow: 1;
    border-bottom: 2px solid #E5E7EB;
    margin: 0 1rem; /* Espacio lateral entre las líneas */
}

.login-google-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem; /* Aumento del padding para el botón */
    border: 1px solid #D1D5DB;
    border-radius: 0.5rem;
    font-size: 0.9rem; /* Tamaño de fuente más grande */
    font-weight: 600; /* Peso de fuente más fuerte */
    transition: background-color 0.3s;
}

.google-logo {
    width: 24px; /* Ancho deseado */
    height: auto; /* Mantener relación de aspecto */
    margin-right: 8px; /* Espacio entre el logo y el texto */
}

.login-google-button:hover {
    background-color: #F3F4F6; /* Color de fondo al pasar el ratón */
}

.login-google-button-disabled {
    cursor: not-allowed;
}
