
.tutor-profiles-container {
    margin-top: 175px;
}

.find-your-tutor {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.tutor-profile {
    position: relative; /* Necessary for absolute positioning of the gradient line */
    background-color: rgba(255, 255, 255, 0.93);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    max-width: 70%;
    margin: 3% auto;
    font-family: 'Arial', sans-serif;
    color: black;
    overflow: hidden; /* Prevent content from overflowing the gradient line */
}

.tutor-profile-info {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.tutor-profile-picture {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    margin-right: 2rem;
    border: 3px solid black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tutor-profile h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
    color: black;
}

.tutor-profile-credits {
    margin: 2rem 0;
    text-align: center;
}

.tutor-profile-credits p {
    font-size: 1.5rem;
    font-weight: 500;
    color: #272660;
}

.tutor-profile-button-group {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

.tutor-profile-credits-button, .tutor-profile-schedule-button, .tutor-profile-toggle {
    background-color: #F04F54;
    border: 3px solid black;
    padding: 12px 25px;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s;
}

.tutor-profile-credits-button:hover, .tutor-profile-schedule-button:hover, .tutor-profile-toggle:hover {
    background-color: #c73a3f;
    transform: translateY(-2px);
}

.tutor-profile-classes h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 3px solid black;
    padding-bottom: 0.5rem;
}

.tutor-profile-classes ul {
    list-style: none;
    padding: 0;
}

.tutor-profile-classes ul li {
    font-size: 1.1rem;
    padding: 0.75rem 0;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.2s;
}

.reschedule-cancel{
    margin-left: 10px;
}

.tutor-profile-classes ul li:hover {
    background-color: rgba(230, 230, 230, 0.8);
}

.tutor-profile-class{
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;
}

.tutor-profile-contact {
    margin-top: 2rem;
}


.tutor-profile-contact h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.tutor-profile-links a {
    display: inline-block;
    border: 3px solid black;
    margin-right: 1rem;
    padding: 12px 20px;
    background-color: #5349EA;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s;
}

.tutor-profile-links a:hover {
    background-color: #33C2A4;
    transform: translateY(-2px);
}

/* Gradient line at the bottom of the tutor profile */
.gradient-line {
    height: 5px;
    width: 100%;
    background: linear-gradient(to right, #272660 0%, #5349EA 17.56%, #CDCAFF 32.82%, #F04F54 42.75%, #F6E43A 54.2%, #ECEAFF 64.89%, #95F5F1 75.57%, #33C2A4 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 8px 8px; /* Ensures the gradient matches the rounded corners */
}

/* Remove underline specifically for the link inside the button group */
.tutor-profile-button-group a {
    text-decoration: none; /* Remove the underline for links */
}

/* Ensure the button inside the link retains its styles */
.tutor-profile-button-group a button {
    text-decoration: none; /* Remove underline for button text */
    background-color: #F04F54; /* Keep your button background */
    border: 3px solid black; /* Keep your button border */
    padding: 12px 25px; /* Keep your button padding */
    font-size: 1.2rem;
    color: white; /* Ensure text color remains white */
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s;
    min-height: 4rem;
}

/* Hover effect for buttons */
.tutor-profile-button-group a button:hover {
    background-color: #c73a3f; /* Maintain your hover color */
    transform: translateY(-2px); /* Keep hover transformation */
}

/* Additional hover effect for the link to ensure no underline appears */
.tutor-profile-button-group a:hover {
    text-decoration: none; /* Ensure no underline on hover */
}




@media only screen and (max-width: 950px) {

    .tutor-profile-picture {

        height: 100px; /* Adjusted size for smaller screens */
        width: 100px; /* Adjusted size for smaller screens */
    }

    .tutor-profile-info h2 {
        font-size: 1.5rem;
    }

    button {
        text-align: center;
    }

    a {
        width: 90px;
        text-align: center;
    }

    .tutor-profile-credits-button, .tutor-profile-schedule-button, .tutor-profile-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        min-width: 90px;
        max-width: 90px;
        text-align: center;
        font-size: 0.8rem;
    }
    .tutor-profile-button-group a button {
        font-size: 0.8rem;
    }

    .tutor-profile-classes ul li {
        font-size: .9rem;
    }

    .reschedule-cancel{
        font-size: .8rem;
        padding: 5px;
    }

    .tutor-profile-contact p {
        font-size: 1.2rem;
    }
    /* Classroom platforms wrap and distribute evenly */
    .tutor-profile-links {
        display: flex;
        flex-wrap: wrap;  /* Enable wrapping of classroom platform links */
        justify-content: space-between; /* Evenly distribute items */
        gap: 10px; /* Space between the links */
    }

    .tutor-profile-links a {
        flex: 1 1 40%; /* Each link takes up 40% of the row, allowing them to wrap */
        text-align: center;
        padding: 10px;
    }
}

