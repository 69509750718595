main {
    align-content: center;
    background-color: #E6E6E6;
    background-image: linear-gradient(to right, #272660 0%, 8.778625726699829%, #5349EA 17.557251453399658%, 25.190839171409607%, #CDCAFF 32.824426889419556%, 37.78625875711441%, #F04F54 42.748090624809265%, 48.473282158374786%, #F6E43A 54.19847369194031%, 59.541985392570496%, #ECEAFF 64.88549709320068%, 70.22900879383087%, #95F5F1 75.57252049446106%, 87.78626024723053%, #33C2A4 100%);
    filter: saturate();
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 50rem;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.square {
    background-color: #FFFFFF;
    opacity: 0.93;
    border-radius: 10px;
    width: 550px;
    max-width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

@keyframes aparecer {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

img.squareimagebig {
    position: absolute;
    height: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    z-index: 2;

    opacity: 0;
    /* Oculta la imagen al inicio */
    animation: aparecer 1s ease-in-out forwards;
    animation-delay: 1s;

    transition: transform 0.6s ease-out;
    /* Hace que el agrandamiento sea más lento y suave */
}

img.squareimagesmall {
    position: absolute;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    z-index: 1;

    opacity: 0;
    /* Oculta la imagen al inicio */
    animation: aparecer 1s ease-in-out forwards;
    animation-delay: 1.5s;

    transition: transform 0.6s ease-out;
    /* Transición más lenta */
}

/* Mantiene la imagen volteada sin afectar la animación */
img[alt="squareimage1"] {
    left: 13%;
}

img[alt="squareimage2"] {
    right: 13%;
}

img[alt="squareimage3"] {
    left: 1%;
}

img[alt="squareimage4"] {
    right: 1%;
}

/* Efecto de agrandamiento con hover */
img.squareimagebig:hover,
img.squareimagesmall:hover {
    transform: scale(1.1) !important;
}

.squareitem {
    text-align: center;
    padding: 10px;
    line-height: 1.5;
}


h1.squareitem {
    color: black;
    /* Primer color del gradiente */
    font-size: 2.5rem;
    font-weight: 700;
    word-spacing: 5px;
    margin: 0;

}

.brew-success {
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 2rem;
    /* Añade espacio debajo */
    color: black;
    /* Primer color del gradiente */
    font-size: 2.5rem;
    font-weight: 700;
    word-spacing: 5px;
    margin: 0;
    ;
}

.learn-tutor {
    font-size: 1.5rem;
    font-family: 'Raleway', sans-serif;
    margin-top: 2rem;
    /* Añade espacio encima */
    color: black;
    /* Primer color del gradiente */
    font-size: 2.5rem;
    font-weight: 700;
    word-spacing: 5px;
    margin: 0;
    ;
}

p {
    text-align: center;
}

h2.squareitem {
    color: #5349EA;
    /* Segundo color del gradiente */
    font-size: 2rem;
    font-weight: 200;
    word-spacing: 5px;
    margin: 0.5rem 0;
}

a.squareitem-button {
    color: white;
    /* Un color más vibrante del gradiente */
    background-color: #F04F54;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 15px;
    border: 2px white solid;
    /* Utiliza otro color del gradiente para el borde */
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

div.squareitem-button {
    margin-top: 2rem;
}

a:hover {
    cursor: pointer;
    background-color: #5349EA;
    /* Color del gradiente para hover */
    color: white;
}

a:active {
    color: white;
}

@media only screen and (max-width: 1500px)  {
    img.squareimagebig {
        display: none;
    }

    img.squareimagesmall {
        display: none;
    }
}

@media only screen and (max-width: 900px) and (orientation: portrait) {
    a.squareitem {
        font-size: 1.5rem;
        padding: 15px;
        width: 80%;
        margin: 10px auto;
    }

    .square {
        width: 90%;
        padding: 1.5rem;
        top: 0;
    }

    h1.squareitem {
        font-size: 2rem;
        font-weight: 700;
        word-spacing: 5px;
        margin: 0;
        line-height: 1;
    }

    h2.squareitem {
        color: #5349EA;
        font-size: 2rem;
        font-weight: 200;
        word-spacing: 5px;
        margin: 0.5rem 0;
        line-height: 0;
    }

    p.squareitem {
        font-size: 1.2rem;
        line-height: 1;
    }

    main {
        height: auto;
        padding: 0px;
    }
}