.courses {
    padding: 2rem; /* Espaciado alrededor del contenido principal */
    background-color: #f0f0f0; /* Fondo claro para mayor contraste */
}

.background {
    background-color: #cecece; /* Color de fondo del contenedor de clases */
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 2rem 0; /* Espaciado superior e inferior */
    border-radius: 10px; /* Bordes redondeados para un aspecto moderno */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.flag {
    border: 6px solid black;
    margin: 20px; /* Ajustar margen para una distribución más uniforme */
    border-radius: 10%; /* Bordes más redondeados */
    height: 150px;
    width: 200px;
    transition: border 0.3s, transform 0.2s; /* Transición suave para el hover */
}

.flag:hover {
    cursor: pointer;
    transform: scale(1.05); /* Aumentar ligeramente el tamaño al hacer hover */
}

.flag:active {
    position: relative;
    top: 3px;
}

p.name {
    color: black;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700; /* Peso de fuente más ligero para una apariencia más moderna */
    margin-top: 1rem; /* Añadir margen superior */
    margin-bottom: 0; /* Eliminar margen inferior para un mejor espaciado */
}

.title {
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    margin: 2rem 0; /* Ajustar margen superior e inferior */
    color: #2A593E; /* Cambiar color del título para mayor contraste */
    text-align: center; /* Centrar el texto */
}

@media only screen and (max-width: 950px) and (orientation: portrait) {
    .flag {
        margin: 20px 1rem; /* Ajustar márgenes para pantallas más pequeñas */
        height: 120px; /* Ajustar altura para pantallas pequeñas */
        width: 160px; /* Ajustar ancho para pantallas pequeñas */
    }

    p.name {
        font-size: 1.2rem; /* Reducir tamaño de fuente en pantallas pequeñas */
    }

    .title {
        font-size: 2rem; /* Reducir tamaño del título en pantallas pequeñas */
    }
}
