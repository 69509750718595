/* Register Component Specific Styles */
.register-container {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9; /* Soft background for a modern feel */
    background: linear-gradient(to bottom, #f9f9f9 0%, #f0f0f0 10%);
}

.register-card {
    width: 100%;
    max-width: 400px; /* Maximum size for larger screens */
    color: #4B5563;
    padding: 2rem; /* Generous internal spacing */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); /* More pronounced shadow */
    border-radius: 1rem; /* More rounded edges */
    background-color: white;
    box-sizing: border-box; /* Ensure padding and border are included in width */
}

.register-title {
    text-align: center;
    margin-bottom: 1.5rem; /* Space between title and form */
}

.register-title h3 {
    color: #1F2937;
    font-size: 1.5rem; /* Larger font size */
    font-weight: 600;
}

.register-form {
    margin-top: 1.25rem;
}

.register-label {
    font-size: 0.9rem; /* Slightly larger font size */
    font-weight: bold;
    color: #4B5563;
}

.register-input {
    width: 100%;
    margin-top: 0.5rem;
    padding: 0.75rem; /* Increased padding for better touch */
    color: #6B7280;
    background-color: #f3f4f6; /* Softer background for fields */
    border: 1px solid #D1D5DB;
    border-radius: 0.5rem;
    box-sizing: border-box; /* Ensures padding and border are included in width */
    transition: border-color 0.3s, background-color 0.3s;
}

.register-input:focus {
    border-color: #4F46E5; /* Border color on focus */
    background-color: white; /* Change to white on focus */
}

.register-error {
    color: #DC2626;
    font-weight: bold;
    margin-top: 0.5rem; /* Space between error message and field */
}

.register-button {
    width: 100%;
    padding: 0.75rem; /* Increased padding for buttons */
    font-weight: 600;
    color: white;
    border-radius: 0.5rem;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin-top: 1rem;
}

.register-button-active {
    background-color: #6366F1; /* Active button color */
}

.register-button-disabled {
    background-color: #D1D5DB; /* Background color for disabled button */
    cursor: not-allowed;
}

.register-footer {
    text-align: center;
    font-size: 0.875rem;
    margin-top: 1rem; /* Top space to separate from form */
}

.register-divider {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem; /* Top space to separate from footer */
}

.register-divider-line {
    flex-grow: 1;
    border-bottom: 2px solid #E5E7EB;
    margin: 0 1rem; /* Side space between lines */
}

.register-google-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem; /* Increased padding for button */
    border: 1px solid #D1D5DB;
    border-radius: 0.5rem;
    font-size: 0.9rem; /* Larger font size */
    font-weight: 600; /* Stronger font weight */
    transition: background-color 0.3s;
}

.register-google-button:hover {
    background-color: #F3F4F6; /* Background color on hover */
}

.register-google-button-disabled {
    cursor: not-allowed;
}
