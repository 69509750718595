/* Privacy Page Specific Styles */
.privacy-container {
    position: relative;
    top: 170px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.privacy-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    color: #272660;
    text-align: center;
    margin: 20px 0;
}

.privacy-effective-date, .privacy-text {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    margin: 10px 0;
    color: #555;
}

.privacy-subtitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.8rem;
    color: #A06E51;
    margin: 15px 0;
}

.privacy-list {
    font-family: 'Open Sans', sans-serif;
    list-style-type: disc;
    padding-left: 20px;
}

.privacy-list-item {
    margin: 5px 0;
}

/* Optional: Dark Mode Styles */
body.dark-mode .privacy-container {
    background-color: #222222;
    color: #eaeaea;
    box-shadow: none;
}

body.dark-mode .privacy-title {
    color: #b0c4de;
}

body.dark-mode .privacy-subtitle {
    color: #e0e0e0;
}

body.dark-mode .privacy-effective-date, 
body.dark-mode .privacy-text, 
body.dark-mode .privacy-list-item {
    color: #eaeaea;
}
