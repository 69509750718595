.membership-container {
    margin-top: 80px;
    padding: 2rem;
    background-color: #f9f9f9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-subtitle {
    text-align: center;
    margin-bottom: 2rem;
}

.membership-header {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
}

.membership-description {
    font-size: 1.2rem;
    color: #555;
}

.membership-options-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.membership-box {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: space-evenly;
}


.membership-button {
    background-color: white;
    border-radius: 10px;
    width: 250px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    display: flex;
    flex-direction: column; /* Ensures the image, h2, and p stack vertically */
    justify-content: space-between; /* Ensures vertical alignment consistency */
    align-items: center; /* Centers content horizontally */
    gap: 1rem; /* Adds space between the image, h2, and p */
    height: 600px; /* Add a fixed height to ensure consistency */
}


.membership-button:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    background-color: white;
    color: white;
}

.membership-button h2 {
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 1rem;
}

.membership-option-icon {
    width: 50px; /* Adjust this size as needed */
    height: 50px; /* Adjust this size as needed */
    margin-bottom: 1rem; /* Space between the image and the h2 */
}


.membership-option-header{
    margin: 10px;
}
.membership-option-description {
    font-size: 1rem;
    color: #777;
    line-height: 1.5;
    flex-grow: 1; /* Allow the description to expand evenly */
    display: flex;
    align-items: center; /* Vertically center the description text */
    justify-content: center; /* Center the description text horizontally */
    text-align: center;
}

.membership-button.selected {
    transform: scale(1.05);
    border: 3px solid #F04F54;
    box-shadow: 0 8px 20px rgba(83, 73, 234, 0.2);
}

.membership-confirm-button {
    margin-top: 2rem;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    background-color: #33C2A4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-confirm-button:hover {
    background-color: #30b397;
}

.membership-divider {
    width: 100%;
    text-align: center;
}

@media (max-width: 900px) {
    .membership-box {
        flex-direction: column;
        align-items: center;
    }

    .membership-button {
        width: 90%;
    }
}
