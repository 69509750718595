.unauthorized-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f9f9f9; /* Light background */
  }
  
  h1.unauthorized-title {
    font-size: 2.5rem;
    color: #8B2C26; /* Orange color for emphasis */
  }
  
  p.unauthorized-description {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #A06E51;
  }
  
  .ice-cream-gif {
    width: 300px; /* Adjust size as needed */
  }
  