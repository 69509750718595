.us {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;/* Añadir espaciado alrededor del contenedor */
    background-color: #f9f9f9; /* Fondo claro para un mejor contraste */
    border-radius: 10px; /* Bordes redondeados para un aspecto moderno */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.division {
    color: #102026;
    padding: 1rem; /* Aumentar espaciado interno */
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 900;
    transition: transform 0.2s; /* Transición suave para la interacción */
    align-items: center;
    max-width: 200px;
    width: 200px;
}

.division:hover {
    transform: scale(1.05); /* Aumentar ligeramente el tamaño al hacer hover */
}

.lineimage {
    height: 100px;
    width: 100px;
    margin-bottom: 1rem; /* Añadir margen inferior para separación con el texto */
    transition: transform 0.2s, background-color 0.2s; /* Transición suave para la imagen y el fondo */
    position: relative; /* Importante para que el pseudo-elemento esté relativo a este contenedor */
    z-index: 1; /* Asegura que la imagen esté encima del círculo */
}

.lineimage::before {
    content: ''; /* Necesario para el pseudo-elemento */
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: black; /* Color del círculo */
    border-radius: 50%; /* Hace que sea un círculo */
    transform: translate(-50%, -50%);
    transition: width 0.3s ease, height 0.3s ease;
    z-index: 1000; /* Asegura que el círculo esté detrás de la imagen */
}

.lineimage:hover::before {
    width: 150px; /* Tamaño del círculo en hover */
    height: 150px; /* Tamaño del círculo en hover */
}

.lineimage:hover {
    transform: scale(1.1); /* Aumentar ligeramente el tamaño al hacer hover */
}

p.division-text {
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.5;
}
.title {
    width: 100%; /* Asegurar que el título ocupe todo el ancho */
    text-align: center; /* Centrar el título */
    margin-bottom: 2rem; /* Espaciado inferior */
    color: #2A593E; /* Color del título para mayor contraste */
}

.title h1 {
    font-size: 3rem; /* Tamaño de fuente grande para el título */
    font-weight: 700; /* Peso de fuente más ligero para una apariencia más moderna */
}

@media only screen and (min-width: 950px) and (orientation: portrait) {
    .lineimage {
        height: 500px; /* Aumentar altura de la línea en pantallas grandes */
    }

    .division {
        font-size: 3.75rem; /* Aumentar tamaño de fuente en pantallas grandes */
        padding: 2rem; /* Aumentar espaciado interno en pantallas grandes */
    }

    .title h1 {
        font-size: 4rem; /* Aumentar tamaño del título en pantallas grandes */
    }
}
