.filter-title {
    color: black;
}

.filter-bar {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 1rem 2rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    font-family: 'Arial', sans-serif;
    color: #333;
    width: 100%;
    max-width: 1200px;
    z-index: 100;
}

.filter-bar select,
.filter-bar input {
    padding: 10px;
    font-size: 1rem;
    border: 2px solid black;
    border-radius: 5px;
    width: 200px;
    background-color: white;
    color: #333;
    transition: border-color 0.3s ease;
}

.filter-bar select:focus,
.filter-bar input:focus {
    outline: none;
    border-color: #F04F54;
}

.filter-bar p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: bold;
    color: black;
}

.filter-bar input[type="range"] {
    width: 90%;
}


.price-filter-popup {
    width: 20%;
    padding: 10px;
    position: absolute;
    top: 100px;
    /* Adjust this according to where you want it to appear */
    left: 70%;
    /* Adjust this according to where you want it to appear */
    background-color: white;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    /* To ensure it's above other elements */
    border-radius: 8px;
}

.price-filter-popup input {
    margin-bottom: 10px;
    width: 90%;
}

.price-filter-popup button {
    display: block;
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #F04F54;
    border: none;
    cursor: pointer;
}

.price-filter-popup button:hover {
    background-color: #ddd;
}



.tutor-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* Allow cards to wrap on smaller screens */
    justify-content: center;
    gap: 20px;
    padding: 2rem;
    background-color: rgba(239, 239, 239, 1);
    font-family: 'Arial', sans-serif;
    color: #e1b010;
    margin-top: 175px;
}


.tutor-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #e0e0e0;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    /* Relative to ensure absolute positioning of gradient */
    padding-bottom: 30px;
    /* Extra padding to prevent content overlapping with gradient */
}

.tutor-content-wrapper {
    width: 100%;
}

.gradient-line {
    height: 5px;
    width: 100%;
    background: linear-gradient(to right, #272660 0%, #5349EA 17.56%, #CDCAFF 32.82%, #F04F54 42.75%, #F6E43A 54.2%, #ECEAFF 64.89%, #95F5F1 75.57%, #33C2A4 100%);
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
}


.profile-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
    flex-shrink: 0;
    flex-direction: column;
}

.profile-pic {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 3px solid black;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tutor-name {
    color: black;
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    position: relative;
    bottom: 2rem;
    right: .5rem;
}

.star-mobile,
.price-mobile {
    display: none;
}

.country-flag {
    width: 50px;
    height: 50px;
    /* Set the height to match the width */
    margin-left: 10px;
    position: relative;
    bottom: 4rem;
    left: 4rem;
    border-radius: 50%;
    /* This makes the shape circular */
    object-fit: cover;
    /* Ensures the flag image is properly contained */
    border: 3px solid black;
}


.tutor-content {
    display: flex;
    justify-content: space-between;
    /* Align horizontally */
    align-items: center;
    /* Center vertically */
    flex: 1;
    gap: 20px;
}

.tutor-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
}

.tutor-info h3 {
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
}

.tutor-details {
    font-size: 1rem;
    color: #F04F54;
    text-align: center;
}

.tutor-description {
    margin-top: 10px;
    font-size: 1.1rem;
    color: #333;
}

.lessons {
    margin-top: 10px;
    font-size: 1.1rem;
    color: black;
    font-weight: bold;

}

.buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    /* Center buttons horizontally */
    align-items: center;
    /* Align buttons vertically */
}


button {
    background-color: #F04F54;
    border: 3px solid black;
    padding: 8px 20px;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s;
}

button:hover {
    background-color: #c73a3f;
    transform: translateY(-2px);
}

.tutor-metrics {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    /* Adjust gap for more consistency */
    font-size: 1rem;
    color: black;
    margin-top: 10px;
}


.tutor-metrics p {
    margin: 5px 0;
}

.video-container {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 350px;
    /* Restrict max width */
    padding: 10px;
}

iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    /* Maintain aspect ratio */
    border-radius: 10px;
    border: none;
    /* Remove default iframe border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for a clean look */
}

video {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.tutor-metrics {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* Centra los elementos horizontalmente */
    justify-content: space-between;
    gap: 5rem;
    font-size: 1rem;
    color: black;
    margin-top: 10px;
    /* Añade espacio entre el video y los datos de métricas */
}

.tutor-metrics p {
    margin: 5px 0;
}

.video-container {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    /* Asegura que los elementos estén uno debajo del otro */
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 350px;
    padding: 10px;
}

.star-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
    font-size: 1rem;
    color: black;
    margin-top: 10px;
}

.star,
.price {
    height: 20px;
    font-weight: 1000;
    font-size: 1.5rem;
    color: black;
}



@media only screen and (max-width: 950px) {

    .filter-bar {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 10px;
        padding: 1rem;
    }

    .filter-bar select,
    .filter-bar input {
        width: 40%;
    }

    .star-mobile,
    .price-mobile {
        display: block;
        font-weight: 900;
        color: rgba(0, 0, 0, 0.756);
    }

    .tutor-content {
        flex-direction: column;
        /* Stack content in a column */
        align-items: center;
        /* Center content horizontally */
    }

    .tutor-metrics {
        justify-content: center;
        /* Center metrics when video is hidden */
    }

    .tutor-info h3 {
        font-size: 2.2rem;
    }

    .profile-container {
        margin: 0;
    }

    .profile-pic {
        width: 100px;
        height: 100px;
    }

    .country-flag {
        width: 25px;
        height: 25px;
        bottom: 2rem;
        left: 2rem;
    }

    .video-container {
        display: none;
    }


    button {
        font-size: 1.2rem;
        padding: 10px 25px;
    }

    .buttons {
        width: 100%;
        /* Ensure buttons take full width */
        justify-content: center;
        /* Center buttons on small screens */
    }

    .view-profile-button {
        display: none;
    }

    .book-trial-button {
        width: 80%;
    }
}