/* Container for the students section */
.students-container {
    margin-top: 175px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 12px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    max-width: 55%;
    font-family: 'Arial', sans-serif;
    color: black;
    overflow: hidden;
    position: relative;
}

/* Title styling */
.students-title {
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1.5rem;
    color: black;
}

/* Error message styling */
.error-message {
    color: red;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
}

/* Student list and individual student card */
.student-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.student-card {
    background-color: rgba(255, 255, 255, 0.96);
    border-radius: 12px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    margin-bottom: 1.8rem;
    position: relative;
    transition: transform 0.2s;
}

.student-card:hover {
    background-color: rgba(255, 255, 255, 0.98);
    transform: translateY(-3px);
}

/* Header of each student card */
.student-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

/* Profile picture styling */
.profile-pic-student {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin-right: 1.5rem;
    border: 3px solid black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Student information */
.student-info {
    flex: 1;
}

.student-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
}

.student-credits {
    font-size: 1.1rem;
    color: #272660;
    margin-top: 0.5rem;
}

/* Toggle button for showing/hiding classes */
.toggle-button {
    margin-left: 1.5rem;
}

.toggle-arrow {
    font-size: 1.3rem;
    background-color: #F04F54;
    border: 3px solid black;
    color: white;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.3s;
}

.toggle-arrow:hover {
    background-color: #c73a3f;
    transform: scale(1.1);
}

/* Container for scheduled classes */
.classes-container {
    margin-top: 1rem;
    background-color: rgba(230, 230, 230, 0.95);
    padding: 1rem;
    border-radius: 12px;
}

/* Class item */
.class-item {
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s;
}

.class-item:hover {
    background-color: rgba(230, 230, 230, 0.8);
}

.class-item p {
    margin: 0;
    font-size: 1rem;
}

.class-actions {
    display: flex;
    gap: 1rem;
}

/* Buttons for rescheduling and canceling */
.reschedule-button,
.cancel-button {
    background-color: #5349EA;
    border: 3px solid black;
    padding: 10px 18px;
    color: white;
    cursor: pointer;
    border-radius: 6px;
    font-size: 1rem;
    transition: transform 0.2s, background-color 0.3s;
}

.reschedule-button:hover,
.cancel-button:hover {
    background-color: #33C2A4;
    transform: scale(1.05);
}

/* Responsive design */
@media only screen and (max-width: 950px) {
    .students-container {
        padding: 1.5rem;
        max-width: 85%;
    }

    .profile-pic-student {
        height: 70px;
        width: 70px;
    }

    .student-name {
        font-size: 1.2rem;
    }

    .student-credits {
        font-size: 1rem;
    }

    .toggle-arrow {
        font-size: 1.1rem;
        padding: 8px;
    }

    .class-item p {
        font-size: 0.9rem;
    }

    .reschedule-button,
    .cancel-button {
        font-size: 0.9rem;
        padding: 8px 14px;
    }
}
